body {
  background-color: #edc58d;
  background-image: url("./background.webp");
  background-repeat: repeat;
  background-size: 100% auto;
  text-align: center;
}

input {
  margin-bottom: 48px;
  border: none;
}
