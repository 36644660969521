.border {
  width: 100%;
  height: 8px;
}

.window {
  height: 70.6vh;
  clip-path: inset(0);
}

.bg-img {
  position: fixed;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent center / calc(100vw - 32px) no-repeat;
  transform: translateX(-50%) translateY(-50%);
  content: "";
}

@media screen and (min-width: 720px) {
  .window {
    height: 508px;
    /* 原画画像が720*508=100:70.6 */
  }

  .bg-img {
    background-size: 720px;
  }
}
